import { cloneDeep, isEqual } from 'lodash';

/**
 * Performs a deep equality comparison between two values
 * @param obj1 First value to compare
 * @param obj2 Second value to compare
 * @returns boolean indicating if the values are deeply equal
 */
export function deepEqual(obj1: DeepEqualType, obj2: DeepEqualType): boolean {
  return isEqual(obj1, obj2);
}

export function deepClone<T>(obj: T): T {
  return cloneDeep(obj);
}

export type DeepEqualType = 
  | string
  | number
  | boolean
  | null
  | undefined
  | Date
  | Array<any>
  | { [key: string]: any }
  | object;
