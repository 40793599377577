import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BaseEditableComponent } from 'src/app/shared/components/base-editable/base-editable.component';
import { ScheduleActionsService } from '../../services/schedule-actions.service';
import { ScheduleService } from '../../services/schedule.service';

@Component({
    selector: 'schedule-add',
    templateUrl: './schedule-add.component.html',
    styleUrls: ['./schedule-add.component.scss'],
    standalone: false
})
export class ScheduleAddComponent extends BaseEditableComponent<any> {

  @ViewChild('scheduleForm')
  public scheduleForm: NgForm;

  actions = this.scheduleActionsService.getActions();

  constructor(
    public scheduleService: ScheduleService,
    private scheduleActionsService: ScheduleActionsService,
  ) {
    super();
    this.actions.saveAction = {
      id: 'saveButton',
      label: 'Save',
      action: () => this.save(),
      disabled: this.scheduleForm?.invalid
    };
    this.oldObject = this.scheduleService.schedule;
  }

  save() {
    if (!this.scheduleForm.valid || this.scheduleService.hasInsecureUrls()) {
      console.error('form not valid: ', this.scheduleForm.errors);
      return;
    }

    this.bypassUnsavedChanges();
    this.scheduleService.addSchedule()
      .finally(() => {
        this.resetUnsavedChanges();
      });
  }

  canDeactivate(): boolean {
    return this.isDeepEqual(this.oldObject, this.scheduleService.schedule);
  }

}
