import {
  Component,
  DestroyRef,
  Inject,
  inject
} from '@angular/core';
import { StateService, Transition } from '@uirouter/angular';

import { TransitionService } from '@uirouter/angular';
import { deepClone, deepEqual } from 'src/app/shared/helpers/object.helper';
import { ModalService } from '../../../components/modals/modal.service';

@Component({
  template: '',
  standalone: false,
})
export abstract class BaseEditableComponent<T> {
  private bypassUnsaved = false;
  protected stateService = inject(StateService);
  protected transitionService = inject(TransitionService);
  protected modalService = inject(ModalService);
  protected destroyRef = inject(DestroyRef, { optional: true });
  private transitionHook: Function;
  private _oldObject: T;
  
  constructor(@Inject(String) currentStateName?: string) {
    this.transitionHook = this.transitionService.onStart(
      currentStateName
        ? { to: (state: any) => state.name.indexOf(currentStateName) === -1 }
        : {},
      (trans: Transition) => {
        if (trans.to().name === trans.from().name) return;
        if (!this.canDeactivate() && !this.bypassUnsaved) {
          trans.abort();
          this.handleUnsavedChanges(trans);
        }
      }
    );
    window.addEventListener('beforeunload', this.beforeUnloadHandler);
    this.destroyRef.onDestroy(() => {
      window.removeEventListener('beforeunload', this.beforeUnloadHandler);
      this.transitionHook();
    });
  }

  beforeUnloadHandler = (event: Event) => {
    if (!this.canDeactivate() && !this.bypassUnsaved) {
      event.preventDefault();
      (event as BeforeUnloadEvent).returnValue = true;
      return 'You have unsaved changes. Do you want to Discard them?';
    }
  };

  private handleUnsavedChanges(trans: Transition) {
    this.modalService
      .confirmDanger(
        'Unsaved Changes',
        'You have unsaved changes. Do you want to Discard them?',
        'Discard'
      )
      .then(() => {
        this.bypassUnsaved = true;
        this.stateService.go(trans.to().name, trans.to().params);
      })
      .catch(() => {});
  }

  abstract canDeactivate(): boolean;

  protected isDeepEqual<T>(obj1: T, obj2: T): boolean {
    return deepEqual(obj1, obj2);
  }

  protected cloneDeep<T>(obj: T): T {
    return deepClone(obj);
  }

  protected bypassUnsavedChanges() {
    this.bypassUnsaved = true;
  }

  protected resetUnsavedChanges() {
    this.bypassUnsaved = false;
  }

  protected set oldObject(obj: T) {
    this._oldObject = obj;
  }

  protected get oldObject(): T {
    return this._oldObject;
  }
}
